<template>
    <section class="pt-lg-8 pt-4 place-holder-white">
        <div class="container full-height pt-lg-4 pt-8">

            <login-heading heading="Forgot Password" size="lg">
                <template #prepend>
                    <span class="text-secondary font-inter-bold fs--1 mb-4"><router-link
                        to="/login/" class="text-decoration-none">Back to Login</router-link></span>
                </template>

                <template #info>
                    <p class="d-inline mr-1">Lost your password? Please enter your email address.</p>
                </template>
            </login-heading>

            <b-form class="row" ref="contactForm" :save-url="addUrl" @success="formSuccess"
                                v-slot="{model, loading}">

                <div class="col-12 col-lg-4 mt-5">

                    <validated-input label="Enter Email ID" class="c-input" name="Email" type="email"
                                     v-model="model.email" :disabled="loading"></validated-input>

                    <btn block text="Submit" :loading="loading" :disabled="loading" loading-text="Sending mail..."
                         class="mt-4 border-r--2 auth-button py-3"></btn>

                    <div v-if="success">
                        <p class="d-inline mr-1 font-inter-light text-2 fs--1 text-center mt-3">Please check your mail.
                            You will receive
                            a link to create a new password</p>
                    </div>
                </div>
            </b-form>

        </div>
    </section>
</template>

<script>
import urls from '../../data/urls';
import LoginHeading from '@components/login/LoginHeading';

export default {
    name: 'UpdatePassword',

    components: { LoginHeading },

    data () {
        return {
            success: false,
            addUrl: urls.auth.generateResetLink
        };
    },

    methods: {

        formSuccess () {
            this.success = true;
        }
    }
};
</script>

<style scoped>
.logo-img {
    width: 16.75rem;
}
</style>
